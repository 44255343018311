import React from 'react';
import leo from '../assets/images/leo-brdar.jpg';

export default function About() {
  return (
    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div>
            <h2 className="text-white mb-4">About Us</h2>
            <div className="text-white-60 col-lg-9 mx-auto">
              <p>
                Cobalt Hills Tech is a software engineering firm specialized in
                consulting, education, and proprietary application and product
                development. Founded by engineers, we believe that technical
                expertise is the driving force behind today's businesses. We are
                committed to providing professional application and product
                development, as well as technical consulting and custom
                programming services.
              </p>
              <p>
                We take pride in building elegant, clean web and mobile
                applications that are a joy to use. We’re experts in building
                products from the ground up and scaling them globally.
              </p>
              <p>
                We believe that software should be created in a partnership
                between companies and their vendors, where both parties have a
                vested interest in the success of their project. This type of
                close working relationship fosters collaboration and trust. As
                experts in your industry, you know what is needed for your
                company's success and with our expertise in creating perfect
                software solutions, we can help you reach that goal.
              </p>
            </div>

            <div className="mx-4">
              <div className="row who-are-we">
                <h4 className="text-white my-5 ml-xl-4 ml-2">Who are we</h4>
                <div className="row text-white-60">
                  <div className="col-md-5 text-center text-white image-with-label">
                    <img className="img-fluid mb-4" src={leo} alt="Leo Brdar" />
                    <h5>Leo Brdar</h5>
                    <label>CEO</label>
                  </div>
                  <div className="col-md-7 px-3">
                    <p>
                      Leo Brdar is an experienced full-stack developer with a
                      demonstrated history of working in the global information
                      technology and services industry for over{' '}
                      {new Date().getFullYear() - 2015 + 1} years. <br />
                      Highly proficient in React, React Native, Redux, GraphQL,
                      Relay, Apollo, Node, TypeScript, Python and MySQL.
                    </p>
                    <p>
                      He is a strong engineering professional with a Master of
                      Computer Science, focused in Computer Software
                      Engineering. <br />
                      Highly ambitious, hard-working individual with high levels
                      of self-motivation, the ability to make decisions and
                      resolve conflicts in a team environment, and an aptitude
                      for time management. Interested in acquiring a broad range
                      of skills and applying them in novel ways.
                    </p>
                    <p>
                      Leo had a wide experience throughout his career including
                      being a lead engineer, engineering manager, architect,
                      teacher and a mentor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
