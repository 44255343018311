import React from 'react';
import config from '../../config';
import { Layout } from '../components';
import { About, Contact, Projects, Services } from '../partials';

const IndexPage = () => (
  <Layout>
    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="mx-auto mt-4 mb-5">{config.subHeading}</h2>
          <a href="#contact" className="btn btn-primary">
            Contact Us
          </a>
        </div>
      </div>
    </header>

    <Services />
    <Projects />
    <About />
    <Contact />
  </Layout>
);

export default IndexPage;
