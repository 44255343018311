import React from 'react';
import demo1 from '../assets/images/demo-img-01.png';
import demo2 from '../assets/images/demo-img-02.jpg';

export default function Projects() {
  return (
    <section id="projects" className="projects-section bg-primary text-white">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-9 mx-auto mb-5">
            <h2 className="mb-5">Our Portfolio</h2>
            <p className="text-white-60">
              If you’re looking for experienced web & mobile development, you’ve
              come to the right place. We build products that matter. Every day,
              we work with startups and global corporations to build products
              that help them change the world.
            </p>
          </div>
        </div>

        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-lg-7">
            <img className="img-fluid mb-3 mb-lg-0" src={demo1} alt="" />
          </div>
          <div className="col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>The Room</h4>
              <div className="text-white-60 mb-0">
                <p>
                  Web platform for semi-automated matching of African top talent
                  with global opportunities that allow them to create impact
                  through their expertise.
                </p>
                <p>
                  The Room is a result of the African Leadership Group's
                  commitment to find, develop, and connect high-performing
                  talent to the world’s leading employers over the last 17
                  years.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-lg-5">
            <div className="featured-text-left text-center text-lg-right">
              <h4>
                Lexia&reg; English <br />
                Language Development&trade;
              </h4>
              <div className="text-white-60 mb-0">
                <p>
                  Cross-platform web and mobile application supporting over 70K
                  emergent bilingual students in over 4K schools around the
                  world.
                </p>
                <p>
                  Leveraging speech recognition technology and adaptive blended
                  learning model to allow personalized learning paths.
                </p>
                <p>
                  Winner of Tech & Learning Best of 2020, American Business
                  Award and EdTech Award.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <img
              className="img-fluid mb-3 mb-lg-0 p-5 pr-lg-2"
              src={demo2}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
