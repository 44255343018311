import React from 'react';

export default function Services() {
  return (
    <section id="services" className="services-section text-center bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 mx-auto mb-5">
            <h2 className="text-white mb-5">At Your Service</h2>
            <p className="text-white-60">
              Cobalt Hills Tech offers web and mobile app development services
              throughout the entire product development cycle: idea exploration,
              design and prototyping, implementation, quality assurance, release
              management and post-launch maintenance.
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-globe text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">Web Development</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    Our team builds strong, scalable and secure{' '}
                    <b>backend and frontend</b> systems that seamlessly fit with
                    your company's needs.
                  </p>
                  <p>
                    We develop <b>custom web applications</b> from scratch as
                    well as <b>integrate systems</b> from other vendors.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-mobile-alt text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">Mobile Development</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    We create <b>cross-platform mobile apps</b> that offer
                    flawless functionality along with a smooth native user
                    experience on both <b>iOS and Android</b>.
                  </p>
                  <p>
                    A mobile app is a valuable tool for converting traffic into
                    <b> loyal customers</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-gears text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">Machine Learning</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    With applications built on <b>deep learning algorithms</b>,
                    we solve complex business problems.
                  </p>
                  <p>
                    <b>AI and machine learning</b> save you time and money, as
                    well as allow you to unlock new business opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-bug-slash text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">Quality Assurance</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    Along with rigorous quality assurance for products we build,
                    we also offer thorough{' '}
                    <b>analysis of your apps & platforms</b>.
                  </p>
                  <p>
                    We test across a wide{' '}
                    <b>range of devices and network conditions</b> to locate
                    bugs and issues before they become problems.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-lightbulb text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">IT Consulting</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    Our experts can{' '}
                    <b>analyze your current processes and systems</b> and advise
                    on how to improve and optimize them.
                  </p>
                  <p>
                    From <b>system modernisation</b> to <b>prototyping</b>, we
                    can offer guidance on any software related matter.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <i className="fas fa-graduation-cap text-primary mb-2" />
                <h4 className="text-uppercase m-0 mt-4">Education</h4>
                <hr className="my-4" />
                <div className="small text-black-60">
                  <p>
                    Whether you're in need of a <b>dedicated mentor</b> or just
                    looking for some <b>educational workshops and trainings</b>,
                    we are committed to sharing our knowledge and experience.
                  </p>
                  <p>
                    Proper use of <b>pull requests</b> is proven to be the best
                    educational tool for software engineers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
